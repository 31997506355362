import React, { Component } from "react"
import Link  from '../components/delayed-gatsby-link';
import MoreButton from "../components/more-button"

class Homepage extends Component {
	constructor(props) {
    super(props);
    this._itemClicked = this.itemClicked.bind(this);
  }

  itemClicked(event){
    window.dispatchEvent( new Event('animateOut') );
  }

	recentArticles(){
		const data = this.props.data
		const that = this.getListItem
		const obj = this;
		return (
			<ul className="ArticleList">
        {data.allWordpressPost.edges.slice(0, 3).map(({ node, index }) => (
          <li className="PostPreview" key={node.slug}>
            {that(node, index, obj)}
          </li>
        ))}
      </ul>
     )
	}

	getListItem (node, index, obj) {
    if(node.acf){
      return(
        <Link delay={1000} to={"/blog/"+node.slug} className="taphover" onClick={obj._itemClicked}>
          <div className="img-wrap fader">
          	<div className="inner">
            	<div className="grimage" style={{backgroundImage: "url(" + node.acf.featured_image.source_url + ")", backgroundSize: "cover"}}></div>
            </div>
          </div>
          <div className="text-wrap fader">
            <time className="eyebrow">{node.date}</time>
            <h2 className="eyebrow-before">{node.title}</h2>
          </div>
        </Link>
      );
    } else {
      return(
        <Link delay={1000} to={"/blog/"+node.slug} className="taphover" onClick={obj._itemClicked}>
          <div className="text-wrap fader">
            <time className="eyebrow">{node.date}</time>
            <h2 className="eyebrow-before">{node.title}</h2>
          </div>
        </Link>
      );
    }
  }

  render() {
    return (
      <div className="page Index ColorKey" data-bgcolor="#ffffff">
      	<div className="main-content">
	        <div className="main-wrap">
		        <section className="TopCopy HomeHero">
		        	<div className="title-wrap">
			          <h2 className="eyebrow fader">Hello, World.</h2>
			          <h1 className="eyebrow-before"><span className="fader">We’re</span> <span className="fader"><span className="highlight">illcreative</span>,</span> <span className="fader"></span><span className="fader"></span><span className="fader"></span><br/><span className="fader">we</span>&nbsp;<span className="fader">make</span> <span className="fader">websites</span><br className="mobile" /> <span className="fader">+</span> <span className="fader">apps</span> <br className="desktop"/><span className="fader">for</span><br className="mobile"/> <span className="fader">emerging</span> <span className="fader">brands.</span> </h1>
			          <div className="button-wrap fader">
			          	<Link delay={1000} to={"/work/"} className="button large taphover" onClick={this._itemClicked}>
					          See Our Work
					        </Link>
					      </div>
		          </div>
		        </section>
		        <section className="RecentWork no-padding-left no-padding-right">
		        	<div className="title-wrap">
			        	<p className="eyebrow fader">Featured Work</p>
			        	<h2 className="eyebrow-before fader">Our latest + greatest.</h2>
			        </div>
		        	<div className="FeaturedProject welly">
			        	<Link delay={1000} to="/work/welly" className="taphover" onClick={this._itemClicked}>
				        	<div className="img-wrap fader">
				        		<video className="autoplay-video" width="320px" height="320px" preload="auto" muted loop playsInline poster="https://cdn.illcreative.com/welly-placeholder.jpg">
				        			<source src="https://cdn.illcreative.com/video/welly-home.mp4" type="video/mp4"/>
				        		</video>
				        	</div>
			        		<div className="title-block fader">
			        			<div className="inner">
				        			<div className="text-wrap">
				                <p className="eyebrow">WELLY</p>
				                <h2 className="eyebrow-before">First Aid for <br/>When Fun Wins</h2>
				              </div>
			                <MoreButton />
			              </div>
		              </div>
		            </Link>
		        	</div>
		        	<div className="FeaturedProject olly">
		        		<Link delay={1000} to="/work/olly" className="taphover" onClick={this._itemClicked}>
			        		<div className="img-wrap fader">
				        		<video className="autoplay-video" width="320px" height="320px" muted loop playsInline poster="https://cdn.illcreative.com/olly-placeholder.jpg">
				        			<source src="https://cdn.illcreative.com/video/olly_1.mp4" type="video/mp4"/>
				        		</video>
				        	</div>
			        		<div className="title-block fader">
			        			<div className="inner">
				        			<div className="text-wrap">
				                <p className="eyebrow">OLLY</p>
				                <h2 className="eyebrow-before">Happy<br/>Inside Out</h2>
				              </div>
			                <MoreButton />
			               </div>
		              </div>
	              </Link>
		        	</div>
		        	<div className="FeaturedProject dphue">
		        		<Link delay={1000} to="/work/dphue" classNam="taphover" onClick={this._itemClicked}>
			        		<div className="img-wrap fader">
				        		<video className="autoplay-video" width="320px" height="320px" muted loop playsInline poster="https://cdn.illcreative.com/dphue-placeholder.jpg">
				        			<source src="https://cdn.illcreative.com/video/dphue-how-it-works1.mp4" type="video/mp4"/>
				        		</video>
				        	</div>
			        		<div className="title-block fader">
			        			<div className="inner">
				        			<div className="text-wrap">
				                <p className="eyebrow">dpHUE</p>
				                <h2 className="eyebrow-before">Color Care. <br/>Healthy Hair. <br/>Everyday.</h2>
				              </div>
			                <MoreButton />
			              </div>
		              </div>
		            </Link>
		        	</div>
		       	</section>
		       	<section className="PostHeroVideo no-padding-left no-padding-right video-holder">
							<figure className="fader">
							<video className="autoplay-video" muted loop playsInline width="320px" height="320px" poster="https://cdn.illcreative.com/sacred_geometry_placeholder.jpg">
							<source src="https://cdn.illcreative.com/video/Sacred+Geometry_4.mp4" type="video/mp4" />
							</video>
							</figure>
							<div className="title-wrap fader">
								<h3>Creativity is at the center of what we do.</h3>
								<Link delay={1000} to={"/services"} className="button black" onClick={this._itemClicked}>
				          Learn More
				        </Link>
							</div>
						</section>
		       	<section className="RecentArticles">
		       		<div className="title-wrap">
			       		<p className="eyebrow fader">Recent Articles</p>
			        	<h2 className="eyebrow-before fader">Our Thoughts</h2>
			        </div>
		        	{this.recentArticles()}
		       	</section>
		      </div>
		    </div>
      </div>
    )
  }
}

export default Homepage

export const pageQuery = graphql`
  query {
  	allWordpressPost(sort: {fields: [date], order:DESC} ) {
      edges {
        node {
          title
          slug
          date(formatString: "MMM DD, YYYY")
          acf {
            featured_image {
              source_url
              alt_text
            }
          }
        }
      }
    }
  }
`